import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import Home from "./containers/Home/Home";
import initialize from "./utils/initialize";
import environment from "./data/environment.json";
import "./styles/index.scss";
import "./styles/vendor.scss";

const currEnv = initialize(
    window.location.host,
    window.location.pathname,
    environment
);

ReactDOM.render(<Home currEnv={currEnv} />, document.getElementById("root"));
